import React, { useEffect } from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import { useQuery } from "@apollo/react-hooks"
import gql from "graphql-tag"
import vibeImage from "../images/vibe-beta.png"
import InstaLogo from "../images/insta.png"

const GET_SPOTS = gql`
  query getBetaSpots {
    getBetaSpots
  }
`

const GET_INVITED_BY = gql`
  query getBetaUser($userId: ID!) {
    getBetaUser(userId: $userId) {
      username
      age
      profilePic
    }
  }
`

export default function Beta(props) {
  const { loading, error, data } = useQuery(GET_SPOTS)
  const userId = props.location.search.split("=").pop()
  const { error: userError, data: userData } = useQuery(GET_INVITED_BY, {
    variables: { userId },
  })

  return (
    <PageWrapper>
      <Wrapper>
        {userData?.getBetaUser?.username && (
          <InviteBox>
            <img
              src={userData.getBetaUser?.profilePic}
              style={{
                width: 50,
                height: 50,
                borderRadius: 25,
                margin: 0,
                objectFit: "cover",
              }}
            />
            <Details>
              <Name>{`@${userData.getBetaUser?.username}, ${userData.getBetaUser?.age}`}</Name>
              <Grey>invited u</Grey>
            </Details>
          </InviteBox>
        )}
        <BackgroundImage>
          <TitleBox>
            <Title>VIBE</Title>
            <SubTitle>fest med dine venner👀</SubTitle>
          </TitleBox>
          <ButtonBox>
            <a
              style={{ textDecorationLine: "none" }}
              href="https://testflight.apple.com/join/Ro9ObTyp"
            >
              <Button>
                <ButtonText>download nu</ButtonText>
              </Button>
            </a>
            <DescText>
              kun {data?.getBetaSpots ? data.getBetaSpots : "få"} pladser
              tilbage
            </DescText>
          </ButtonBox>
        </BackgroundImage>
        <InstaBox>
          <a
            style={{ textDecorationLine: "none" }}
            href="https://www.instagram.com/join_vibe/"
          >
            <InstaImage />
          </a>
        </InstaBox>
      </Wrapper>
    </PageWrapper>
  )
}

// Page Wrapper
const PageWrapper = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: #121212;
`

// Content wrapper
const Wrapper = styled.div`
  height: 100%;
  width: 100%;

  align-self: center;
  background-color: #121212;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
`

const InviteBox = styled.div`
  position: absolute;
  top: 30px;
  align-self: center;
  width: 220px;
  height: 60px;
  background-color: #3a3a3a;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
`
const Details = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  height: 50px;
  margin-left: 15px;
`

const Name = styled.p`
  font-family: Arial, Helvetica Neue, Helvetica, sans-serif;
  font-weight: 600;
  color: #fff;
  font-size: 16px;
  margin: 0;
  text-align: left;
`

const Grey = styled.p`
  font-family: Arial, Helvetica Neue, Helvetica, sans-serif;
  font-weight: 600;
  color: #8a8a8a;
  font-size: 14px;
  margin: 0;
  line-height: 15px;
  text-align: left;
`

const BackgroundImage = styled.div`
  background-image: url(${vibeImage});
  margin-top: 80px;
  background-color: #121212;
  width: 100vw;
  max-width: 600px;
  min-height: 600px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  align-self: center;

  display: flex;
  justify-content: center;
  aling-items: center;
  flex-direction: column;
`
const TitleBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

const Title = styled.p`
  font-family: Gotham;
  font-size: 55px;
  letter-spacing: 2px;
  text-shadow: 0px 2px 0px rgba(0, 0, 0, 0.2);
  color: #fdde68;
`
const SubTitle = styled.p`
  font-family: Arial, Helvetica Neue, Helvetica, sans-serif;
  font-weight: 600;
  color: #fff;
  margin-top: 5px;
  font-size: 24px;
  letter-spacing: 1px;
  line-height: 28px;
  width: 200px;
  text-align: center;
  text-shadow: 0px 2px 0px rgba(0, 0, 0, 0.2);
`
const ButtonBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 15px;
  flex-direction: column;
`

const DescText = styled.p`
  font-family: Arial, Helvetica Neue, Helvetica, sans-serif;
  font-weight: 600;
  color: #fff;
  font-size: 18px;
  width: 180px;
  margin-top: 15px;
  text-align: center;
  text-shadow: 0px 2px 0px rgba(0, 0, 0, 0.2);
`

const Button = styled.div`
  width: 170px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  background-color: #fdde68;
`

const ButtonText = styled.p`
  text-align: center;
  vertical-align: middle;
  margin: 0;
  margin-bottom: 1px;
  text-decoration: none;
  color: #333;

  font-family: Arial, Helvetica Neue, Helvetica, sans-serif !important;
  font-weight: bold;
  font-size: 20px;
  line-height: 50px;
`
const InstaBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  align-self: center;
  background-color: #121212;
  width: 100%;
  margin-top: 25px;
`
const InstaImage = styled.img`
  content: url(${InstaLogo});
  flex: 1;
  width: 70px;
  height: 70px;
  object-fit: contain;
`
